.register-form {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.register-form .form-control {
  margin-bottom: 15px;
}

.register-form button {
  width: 100%;
}
