.favorites-container {
  padding: 16px;
}

.favorites-item {
  display: flex;
  align-items: center;
}

.favorites-item img {
  width: 100%;
  max-width: 60px;
  height: auto;
}

.favorites-item h5 {
  margin: 0;
  font-size: 16px;
}

.favorites-item p {
  margin: 0;
  font-size: 14px;
  color: gray;
}

.text-right {
  text-align: right;
}
